import * as React from "react";

import {
  AddPatientChartModal,
  ChartNotesModal,
  ChartPicker,
  OrderChartNotesModal,
  SidebarChartPatientCard,
  VitalsTimerBadge,
  useChartSectionNotesCount,
} from "modules/charts-shared";
import {
  AuditCommentsSectionIndicator,
  AuditCommentsSharedSectionIndicator,
} from "./audit-comments";
import {
  BackButton,
  Counter,
  Loading,
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonContentProps,
  Sidebar,
  SidebarActions,
  SidebarBottom,
  SidebarContent,
  SidebarHeader,
  SidebarUserView,
} from "shared";
import {
  Box,
  Button,
  ChakraProps,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { EmrNotes, EmrPerson } from "@medstonetech/slate-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AddRounded, BubbleChatIndicator } from "icons";
import { ChartSectionHeaderMenu } from "./ChartSectionHeader";
import { DiagramCanvasModal } from "./diagrams/DiagramCanvasModal";
import { FaqButton } from "modules/faq/components";
import { OrderFormElementType, OrderGroup } from "enums";
import { SharedChartNotesModal } from "./chart-notes/SharedChartNotesModal";
import { useDiagramsCounter } from "./diagrams/api";
import { useChartTrackingContext, usePermissions } from "contexts";
import { useScrollbarWidth } from "hooks";
import { useIvAndMedicationCounters } from "modules/triage/api";
import { useEncounterOrderCounters } from "modules/orders/api";
import { useEKGCounters } from "modules/provider/api";

type ChartsSidebarProps = {
  children?: React.ReactNode;
};
type ChartNavigationButtonNotesProps = {
  hasNotes: true;
} & (
  | {
      notesType: "general" | "shared";
    }
  | {
      notesType: "order";
      orderType: OrderGroup;
    }
);

type ChartNavigationButtonProps = {
  sectionId: string;
  encounterId: string;
  to: string;
  chartCode: string;
  hasDiagrams?: boolean;
  isAccessible?: boolean;
  isShared?: boolean;
  showIvMedicationCounters?: boolean;
  showOrderCounters?: OrderFormElementType;
  showEKGCounters?: boolean;
  isSaved?: boolean;
  isLastSaved?: boolean;
  hasPendingOrdersCancellation?: boolean;
} & { hasAudits?: boolean } & (
    | { hasNotes?: false }
    | ChartNavigationButtonNotesProps
  ) &
  NavigationButtonContentProps;

type AddNewChartButtonProps = {
  encounterId: string;
  defaultIsOpen?: {
    value: boolean;
    chartType: string;
  };
};

function AddNewChartButton(props: AddNewChartButtonProps) {
  const { encounterId, defaultIsOpen } = props;
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: defaultIsOpen?.value,
  });

  return (
    <>
      <Button
        fontSize="0.9375rem"
        color="blue"
        display="flex"
        variant="label"
        alignItems="center"
        marginLeft="7px"
        onClick={onOpen}
      >
        <Icon as={AddRounded} fontSize="1.125rem" marginRight="16px" />
        Add New Chart
      </Button>
      <AddPatientChartModal
        isOpen={isOpen}
        onClose={onClose}
        encounterId={encounterId}
        defaultChartType={defaultIsOpen?.chartType}
      />
    </>
  );
}

type DiagramsButtonProps = {
  encounterId: string;
  chartCode: string;
};

function DiagramsButton(props: DiagramsButtonProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { chartCode, encounterId } = props;
  const { data, isLoading } = useDiagramsCounter(encounterId, chartCode);

  const diagramsCounter = data?.data || 0;

  return (
    <>
      <Box position="relative">
        <IconButton
          aria-label="notes"
          isLoading={isLoading}
          icon={
            <Box
              width="1.25rem"
              height="1.25rem"
              borderRadius="50%"
              bg={diagramsCounter > 0 ? "blue" : "gray.450"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={EmrPerson}
                color="gray.100"
                width="0.4375rem"
                height="0.5.875rem"
              />
            </Box>
          }
          variant="icon"
          fontSize="1.4375rem"
          minWidth="unset"
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
        />
        {diagramsCounter > 0 && (
          <Counter
            size="sm"
            count={diagramsCounter}
            position="absolute"
            right="-2px"
            top="-2px"
          />
        )}
      </Box>
      <React.Suspense fallback={"Loading..."}>
        <DiagramCanvasModal
          isOpen={isOpen}
          diagrams={[]}
          onClose={onClose}
          {...props}
        />
      </React.Suspense>
    </>
  );
}

type CharNotesButtonProps = {
  onOpen: () => void;
  encounterId: string;
  chartCode: string;
  sectionCode: string;
};

function ChartNotesButton(props: CharNotesButtonProps) {
  const { onOpen, encounterId, chartCode, sectionCode } = props;

  const { data } = useChartSectionNotesCount({
    encounterId,
    chartCode,
    sectionCode,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <IconButton
      aria-label="notes"
      icon={
        <Icon
          as={EmrNotes}
          color={
            sectionNotesCount && sectionNotesCount > 0 ? "blue" : "gray.450"
          }
          fontSize="1.25rem"
        />
      }
      variant="icon"
      fontSize="1.4375rem"
      minWidth="unset"
      onClick={(e) => {
        e.preventDefault();
        onOpen();
      }}
    />
  );
}

type CountersProps = {
  ordered: number;
  inProcess: number;
  completed: number;
  isLoading: boolean;
};

function Counters({ ordered, inProcess, completed, isLoading }: CountersProps) {
  const boxStyle: ChakraProps = {
    w: "20px",
    h: "20px",
    color: "white",
    borderRadius: "5px",
    textAlign: "center",
    fontSize: "14px",
  };

  return (
    <Flex justifyContent="space-between" gap={1} alignItems="center">
      {isLoading ? (
        <Loading spinnerProps={{ size: "sm", color: "blue" }} />
      ) : (
        <>
          <Box bgColor="orange" {...boxStyle}>
            {ordered}
          </Box>
          <Box bgColor="blue" {...boxStyle}>
            {inProcess}
          </Box>
          <Box bgColor="green" {...boxStyle}>
            {completed}
          </Box>
        </>
      )}
    </Flex>
  );
}

function ChartNavigationButton(props: ChartNavigationButtonProps) {
  const {
    label,
    labelIcon,
    sectionId,
    hasNotes,
    hasAudits,
    isShared,
    to,
    chartCode,
    encounterId,
    hasDiagrams,
    showIvMedicationCounters,
    showOrderCounters,
    showEKGCounters,
    isSaved,
    isLastSaved,
    hasPendingOrdersCancellation,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  const showNotes = hasNotes && fromAudits;
  const showAudits = hasAudits && fromAudits;
  const showGeneralNotesModal = showNotes && props.notesType === "general";
  const showSharedNotesModal = showNotes && props.notesType === "shared";
  const showOrderNotesModal = showNotes && props.notesType === "order";

  const { data: ivMedicationCounters, isLoading: ivMedicationCountersLoading } =
    useIvAndMedicationCounters(encounterId, {
      enabled: showIvMedicationCounters,
    });

  const { data: orderCounters, isLoading: orderCountersLoading } =
    useEncounterOrderCounters(encounterId, showOrderCounters ?? "Lab", {
      enabled: Boolean(showOrderCounters),
    });

  const { data: ekgCounters, isLoading: ekgCountersLoading } = useEKGCounters(
    encounterId,
    {
      enabled: showEKGCounters,
    }
  );

  const counters = {
    ordered:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.ordered
        : showEKGCounters
        ? ekgCounters?.data.ordered
        : orderCounters?.data.ordered) ?? 0,
    inProcess:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.inProcess
        : showEKGCounters
        ? ekgCounters?.data.inProcess
        : orderCounters?.data.inProcess) ?? 0,
    completed:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.completed
        : showEKGCounters
        ? ekgCounters?.data.completed
        : orderCounters?.data.completed) ?? 0,
  };

  return (
    <>
      <NavigationButton to={to} key={to} isLastSaved={isLastSaved}>
        <NavigationButtonContent
          label={label}
          labelIcon={labelIcon}
          isSaved={isSaved}
        />
        <HStack spacing="14px">
          {label === "Vitals" && <VitalsTimerBadge encounterId={encounterId} />}
          {hasDiagrams && !fromAudits && (
            <DiagramsButton encounterId={encounterId} chartCode={chartCode} />
          )}

          {showAudits &&
            (isShared ? (
              <AuditCommentsSharedSectionIndicator
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            ) : (
              <AuditCommentsSectionIndicator
                chartCode={chartCode}
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            ))}

          {hasNotes && showNotes && (
            <ChartNotesButton
              onOpen={onOpen}
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionId}
            />
          )}

          {(showIvMedicationCounters ||
            Boolean(showOrderCounters) ||
            showEKGCounters) && (
            <Counters
              ordered={counters.ordered}
              inProcess={counters.inProcess}
              completed={counters.completed}
              isLoading={
                ivMedicationCountersLoading ||
                orderCountersLoading ||
                ekgCountersLoading
              }
            />
          )}
          {hasPendingOrdersCancellation && (
            <Icon as={BubbleChatIndicator} color="red" fontSize="22px" />
          )}
        </HStack>
      </NavigationButton>
      {showGeneralNotesModal && (
        <ChartNotesModal
          chartId={chartCode}
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionId={sectionId}
          title={label}
        />
      )}
      {showSharedNotesModal && (
        <SharedChartNotesModal
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionCode={sectionId}
          title={label}
        />
      )}
      {showOrderNotesModal && (
        <OrderChartNotesModal
          isOpen={isOpen}
          onClose={onClose}
          encounterId={encounterId}
          chartCode={chartCode || ""}
          orderType={props.orderType}
          title={label}
        />
      )}
    </>
  );
}

function ChartsSidebar(props: ChartsSidebarProps) {
  const { children } = props;
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { isPastEncounter } = useChartTrackingContext();
  const navigate = useNavigate();
  const { scope } = usePermissions();
  const location = useLocation();

  const createChart = new URLSearchParams(location.search).get("createChart");

  const [isEditMode, setIsEditMode] = React.useState(!!createChart);
  const [hasActiveCharts, setHasActiveCharts] = React.useState(false);
  const { ref, scrollbarWidth } = useScrollbarWidth<HTMLDivElement>();
  const state = location.state as {
    fromLabel?: string;
    fromRoute?: string;
  } | null;
  const contentPadding = `12px ${24 - scrollbarWidth}px 8px 24px`;

  const fromAudits = location.pathname.includes("audits");
  const fromLabs = location.pathname.includes("labs");
  const fromRadiology = location.pathname.includes("radiology");
  const fromInProcess = location.pathname.includes("in-process");
  const fromOpenCharts = location.pathname.includes("open-charts");

  const fromLabel = fromAudits
    ? "Audits"
    : fromInProcess
    ? "InProcess"
    : fromLabs
    ? "Labs"
    : fromRadiology
    ? "Radiology"
    : fromOpenCharts
    ? "Open Charts"
    : "InProcess";
  const fromRoute = fromAudits
    ? "/audits"
    : fromInProcess
    ? "/in-process"
    : fromLabs
    ? "/labs"
    : fromRadiology
    ? "/radiology"
    : fromOpenCharts
    ? "/open-charts"
    : "/in-process";

  const addDeletePermission =
    scope("nurse:adddelete").isAccessible ||
    scope("provider:adddelete").isAccessible;

  return (
    <Sidebar>
      <SidebarActions>
        {!isPastEncounter && (
          <BackButton
            onClick={() =>
              navigate(state?.fromRoute ? state?.fromRoute : fromRoute, {
                replace: true,
              })
            }
          >
            {state?.fromLabel ? state?.fromLabel : fromLabel}
          </BackButton>
        )}
        <ChartSectionHeaderMenu encounterId={encounterId} />
      </SidebarActions>
      <SidebarHeader padding="0 17px">
        <SidebarChartPatientCard patientId={encounterId} marginBottom="10px" />
      </SidebarHeader>
      <SidebarContent
        ref={ref}
        padding={contentPadding}
        style={{ scrollbarGutter: "auto" }}
        overflowX="hidden"
      >
        <ChartPicker
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setHasActiveCharts={setHasActiveCharts}
        />
        <Divider
          margin="10px -7px 10px -7px"
          borderColor="gray.500"
          width="unset"
        />
        {isEditMode && addDeletePermission ? (
          <AddNewChartButton
            encounterId={encounterId}
            defaultIsOpen={{
              value: !!createChart || !hasActiveCharts,
              chartType: createChart || "demographics",
            }}
          />
        ) : (
          children
        )}
      </SidebarContent>
      <SidebarBottom>
        <FaqButton />
        <SidebarUserView />
      </SidebarBottom>
    </Sidebar>
  );
}

export type { ChartsSidebarProps, ChartNavigationButtonProps };
export { ChartsSidebar, ChartNavigationButton };
