import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabPanel,
  TabPanels,
  useDisclosure,
} from "@chakra-ui/react";
import faker from "faker";
import { OrderItem } from "modules/charts-shared/types";
import { Concentration, OrderElementResponse } from "modules/order-list/types";
import { useVitals } from "modules/vitals/api";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, TabList, Tabs } from "shared";
import { CalculationModal } from "./CalculationModal";
import { CustomDose } from "./CustomDose";
import { FavoriteDoses } from "./FavoriteDoses";

type AddMedicationOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  item: OrderElementResponse;
  onAddItem: (item: OrderItem) => void;
  encounterId: string;
  isAddingLoading?: boolean;
};

type MedicationOrderForm = {
  medicationName: string;
  doseUnit: string;
  doseAmount: number;
  route: string;
  administrationTime?: Date | string;
  notes: string;
  weight: number;
  amount: number | null;
  concentration: Partial<Concentration>;
  doseId?: string;
};

function AddMedicationOrderModal({
  item,
  isOpen,
  onClose,
  onAddItem,
  encounterId,
  isAddingLoading,
}: AddMedicationOrderModalProps) {
  const [activeTab, setActiveTab] = useState(0);
  const {
    isOpen: isCalculationOpen,
    onOpen: onCalculationOpen,
    onClose: onCalculationClose,
  } = useDisclosure();

  const { data } = useVitals(encounterId);

  const form = useForm<MedicationOrderForm>({
    defaultValues: {
      medicationName: item?.description,
      amount: null,
      route: "PO",
    },
  });
  const { handleSubmit, formState, reset, getValues, watch, setValue } = form;

  const getConcentration = () => {
    const concentration = getValues("concentration");
    if (concentration) {
      return ` (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit}) `;
    }
    return "";
  };

  const getLocation = (
    doseAmount?: number,
    doseUnit?: string,
    route?: string
  ) => {
    const concentration = getConcentration();

    if (doseAmount || doseUnit || route || concentration) {
      return `${doseAmount ?? ""} ${doseUnit ?? ""}${getConcentration()} ${
        route ?? ""
      }`;
    }

    return "";
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (formData: MedicationOrderForm) => {
    const { doseAmount, doseUnit, route, administrationTime, notes, amount } =
      formData;
    if (item) {
      onAddItem({
        id: faker.datatype.uuid(),
        description: `${item.description} ${
          `${amount ? `${amount} mL` : ""}` ?? ""
        }`,
        orderElementType: item.orderFormElementType,
        orderElementCategory: item.orderFormElementCategory || null,
        date: new Date(),
        location: getLocation(doseAmount, doseUnit, route),
        notes,
        administrationTime: administrationTime?.toString() || "",
        route,
        cptCode: item.cptCode,
        studyTypeId: item.examID,
        instructions: item.instructions,
      });
    }
    handleClose();
  };

  const {
    concentration,
    doseAmount,
    doseUnit,
    weight,
    administrationTime,
    route,
  } = watch();

  useEffect(() => {
    if (data?.data.vitalsBMI?.weight) {
      setValue("weight", data.data.vitalsBMI?.weight);
    }
  }, [data, setValue]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay maxH="100%" />
        <FormProvider {...form}>
          <ModalContent
            w="540px"
            maxWidth="540px"
            height="1040px"
            bg="gray.200"
            display="flex"
            flexDirection="column"
          >
            <ModalHeader textAlign="center">Medication Order</ModalHeader>
            <Box
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              flex={1}
              display="flex"
              flexDirection="column"
            >
              <ModalBody display="flex" flexDir="column" px="20px" py="20px">
                <Tabs
                  isFitted
                  size="sm"
                  index={activeTab}
                  variant="solid-rounded"
                  onChange={(index) => {
                    reset();
                    setActiveTab(index);
                  }}
                >
                  <TabList index={activeTab}>
                    <Tab
                      maxH="30"
                      _selected={{ bgColor: "white", color: "blue" }}
                    >
                      Favorite Doses
                    </Tab>
                    <Tab
                      maxH="30"
                      _selected={{ bgColor: "white", color: "blue" }}
                    >
                      Custom Dose
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {item && activeTab === 0 && (
                        <FavoriteDoses
                          item={item}
                          showCalculation={onCalculationOpen}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {item && activeTab === 1 && (
                        <CustomDose
                          item={item}
                          showCalculation={onCalculationOpen}
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalBody>
              <ModalFooter
                justifyContent="space-around"
                bgColor="white"
                borderBottomRadius="md"
                px={2}
              >
                <Button
                  variant="outlineSquared"
                  colorScheme="gray"
                  onClick={handleClose}
                  width="240px"
                >
                  Cancel
                </Button>
                <Button
                  variant="outlineSquared"
                  type="submit"
                  bgColor="blue"
                  color="white"
                  disabled={!formState.isValid || !administrationTime || !route}
                  width="240px"
                  isLoading={isAddingLoading}
                >
                  Add to Queue
                </Button>
              </ModalFooter>
            </Box>
          </ModalContent>
        </FormProvider>
      </Modal>
      {isCalculationOpen &&
        concentration.doseAmount &&
        concentration.doseUnit &&
        concentration.volumeAmount &&
        concentration.volumeUnit &&
        doseAmount &&
        doseUnit &&
        weight && (
          <CalculationModal
            isOpen={isCalculationOpen}
            onClose={onCalculationClose}
            concentration={concentration}
            doseAmount={doseAmount}
            doseUnit={doseUnit}
            weight={weight}
          />
        )}
    </>
  );
}

export { AddMedicationOrderModal };
export type { MedicationOrderForm };
