import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useCancelOrder(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["cancelOrder", { orderId }],
    () => axiosOrderInstance.put(`v1/${orderId}/cancel`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrder", { orderId }]);
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
      },
    }
  );
}

export { useCancelOrder };
