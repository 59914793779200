import {
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { EmrDocument } from "@medstonetech/slate-icons";
import { useOrderFormFavorites } from "modules/charts-shared/api/queries/useOrderFormFavorites";
import {
  ChartRouteBaseParams,
  OrderItem,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import { OrderElementResponse } from "modules/order-list/types";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AddOrderItemModal } from "../AddOrderItemModal";
import { ChartSectionHeader } from "../chart";
import { OrderFormCart } from "../order-form-cart/OrderFormCart";
import { OrderFormList } from "./OrderFormList";
import { Button } from "shared";
import { MdChevronLeft } from "react-icons/md";
import { AddMedicationOrderModal } from "../add-medication-order-modal";
import {
  OrderQueueRequestDto,
  useAddOrderToQueue,
  useDeleteOrderQueue,
  useOrderQueue,
} from "modules/charts-shared/api";
import { useToast } from "hooks";
import { extractApiErrorMessage } from "utils";
import { formMessages, genericErrors } from "messages";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";

const sectionId = "SPOF";

type OrderFormProps = SharedChartBaseProps & {
  onBack?: () => void;
};

function OrderForm(props: OrderFormProps) {
  const { chartCode, chartType, onBack, isReadOnly = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";
  const toast = useToast();

  const { data } = useOrderFormFavorites();
  const favorites = React.useMemo(() => (data ? data.data : []), [data]);
  const [addingItem, setAddingItem] =
    React.useState<OrderElementResponse | null>(null);

  const { mutateAsync: addOrderToQueue, isLoading: isAddingLoading } =
    useAddOrderToQueue({
      encounterId,
      chartCode,
    });

  const {
    mutateAsync: deleteOrderQueue,
    isLoading: isDeleteOrderQueueLoading,
  } = useDeleteOrderQueue({
    encounterId,
  });

  const { data: orderQueueData, isLoading: isOrderQueueLoading } =
    useOrderQueue({ encounterId });

  const handleAddItem = async (item: OrderItem) => {
    try {
      const payload: OrderQueueRequestDto = {
        orderBy: userId,
        description: item.description,
        notes: item.notes,
        elementType: item.orderElementType,
        elementCategory: item.orderElementCategory ?? undefined,
        location: item.location,
        route: item.orderElementRoute,
        administrationTime: item.administrationTime,
        cptCode: item.cptCode,
        studyTypeId: item.studyTypeId,
        instructions: item.instructions,
      };
      await addOrderToQueue(payload);
      toast({
        status: "success",
        description: formMessages.createSuccess("Order Queue"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const handleAddingItem = (item: OrderElementResponse) => {
    setAddingItem(item);
  };

  const handleDeleteCartItem = async (id: string) => {
    try {
      await deleteOrderQueue([id]);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Order"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const handleClear = async () => {
    try {
      const orderIds = orderQueueData?.data.map((order) => order.id) ?? [];
      await deleteOrderQueue(orderIds);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Order"),
      });
      onBack?.();
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const orderQueue: OrderItem[] =
    orderQueueData?.data.map((order) => {
      return {
        id: order.id,
        description: order.description,
        notes: order.notes,
        date: order.createdAt,
        orderElementCategory: order.elementCategory ?? null,
        location: order.location,
        orderElementType: order.elementType,
        orderElementRoute: order.route,
        administrationTime: order.administrationTime,
      };
    }) ?? [];

  return (
    <Box height="100%" w="100%">
      {Boolean(onBack) && (
        <Button
          variant="label"
          ml="20px"
          color="blue"
          mt="20px"
          onClick={onBack}
        >
          <MdChevronLeft fontSize="2rem" />
          Back
        </Button>
      )}
      <Flex height="100%" w="100%">
        <Box
          flex={2}
          overflow="auto"
          overflowX="hidden"
          p="20px"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          <ChartSectionHeader
            icon={<Icon as={EmrDocument} />}
            chartId={chartCode}
            encounterId={encounterId}
            sectionId={sectionId}
            showNotesBtn={chartType === "Chart" || chartType === "Triage"}
          >
            Orders {isReadOnly ? "(Read Only)" : ""}
          </ChartSectionHeader>
          <Tabs isFitted margin="0.15rem">
            <TabList>
              <Tab>Labs</Tab>
              <Tab>Radiology</Tab>
              <Tab>Meds & Treatments</Tab>
              {/* <Tab>Actions & Procedures</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Lab"
                  favorites={favorites}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Radiology"
                  favorites={favorites}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="MedAndTreatment"
                  favorites={favorites}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          flex={1}
          pt="35px"
          px="20px"
          display="flex"
          flexDirection="column"
          overflow="auto"
          backgroundColor="gray.200"
        >
          <OrderFormCart
            items={orderQueue}
            onDelete={handleDeleteCartItem}
            onClear={handleClear}
            chartCode={chartCode}
            isOrderQueueLoading={isOrderQueueLoading}
            isDeleteOrderQueueLoading={isDeleteOrderQueueLoading}
          />
        </Box>
        <AddOrderItemModal
          isOpen={
            Boolean(addingItem) &&
            addingItem?.orderFormElementType !== "Medication"
          }
          onClose={() => setAddingItem(null)}
          onAddItem={handleAddItem}
          item={addingItem}
          favorite={favorites.find(
            (fav) => fav.orderFormElementId === addingItem?.id
          )}
        />
        {addingItem && (
          <AddMedicationOrderModal
            isOpen={
              Boolean(addingItem) &&
              addingItem?.orderFormElementType === "Medication"
            }
            onAddItem={handleAddItem}
            onClose={() => setAddingItem(null)}
            item={addingItem}
            encounterId={encounterId}
            isAddingLoading={isAddingLoading}
          />
        )}
      </Flex>
    </Box>
  );
}

export { OrderForm };
