import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useToast } from "hooks";
import { Edit, MinusRoundFilled } from "icons";
import { formMessages } from "messages";
import {
  useAddChartFavorite,
  useDeleteChartFavorite,
  useUpdateChartFavoriteInfo,
} from "modules/charts-shared/api";
import {
  ChartFavorite,
  ChartFavoriteRequest,
} from "modules/charts-shared/types";
import {
  mapFormToPayload,
  mapPayloadToFormObject,
} from "modules/charts-shared/utils";
import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Button, Card, Input, Label, WarningDialog } from "shared";
import { extractApiErrorMessage } from "utils";

type ChartFavoriteRenameModalProps = {
  item: ChartFavorite;
  isOpen: boolean;
  onClose: () => void;
};

function ChartFavoriteRenameModal({
  item,
  isOpen,
  onClose,
}: ChartFavoriteRenameModalProps) {
  const toast = useToast();

  const [name, setName] = useState(item.title);

  const {
    mutateAsync: updateChartFavorite,
    isLoading: isUpdateChartFavoriteLoading,
  } = useUpdateChartFavoriteInfo({
    id: item.id,
    sectionCode: item.code,
  });

  const handleRename = async () => {
    try {
      const payload = {
        title: name,
        isFavorite: item.isFavorite,
      };

      await updateChartFavorite(payload);

      toast({
        status: "success",
        description: formMessages.updateSuccess("Chart Favorite"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end" }}
        height="230px"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader
          display="flex"
          justifyContent="center"
          fontSize="1rem"
          padding="20px 16px 30px"
          alignItems="center"
        >
          <Heading
            fontWeight="600"
            fontSize="1.0625rem"
            textAlign="center"
            display="flex"
            alignItems="center"
            gap={1}
          >
            Rename Favorite
          </Heading>
        </ModalHeader>
        <ModalBody p="15px">
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            bg="white"
          />
          <Flex justifyContent="space-between" mt="20px">
            <Button
              variant="outlineSquared"
              color="red"
              borderColor="red"
              onClick={onClose}
              borderRadius="10px"
              w="245px"
            >
              Cancel
            </Button>
            <Button
              variant="outlineSquared"
              disabled={name === "" || isUpdateChartFavoriteLoading}
              onClick={handleRename}
              isLoading={isUpdateChartFavoriteLoading}
              borderRadius="10px"
              w="245px"
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

type TreatmentItemProps = {
  item: ChartFavorite;
  onDelete: () => void;
  isDeleteLoading: boolean;
  isEdit: boolean;
  chartFavoriteIdSelected?: string | null;
  onCloseModal: () => void;
  chartCode: string;
};

function ChartFavoriteItem({
  item,
  onDelete,
  isDeleteLoading,
  isEdit,
  onCloseModal,
  chartCode,
}: TreatmentItemProps) {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { reset, setValue } = useFormContext();

  const {
    mutateAsync: updateChartFavorite,
    isLoading: isUpdateChartFavoriteLoading,
  } = useUpdateChartFavoriteInfo({
    id: item.id,
    sectionCode: item.code,
  });

  const handleToggleFavorite = async () => {
    try {
      const payload = {
        title: item.title,
        isFavorite: !item.isFavorite,
      };

      await updateChartFavorite(payload);

      toast({
        status: "success",
        description: formMessages.updateSuccess("Chart Favorite"),
      });
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  const handleSelectFavorite = async () => {
    try {
      const form = mapPayloadToFormObject(
        item.subSections.map((x) => ({
          ...x,
          id: "",
          answers: x.answers.map((answer) => ({
            qCode: `${chartCode}${answer.qCode}`,
            value: answer.value,
          })),
        }))
      );
      reset(form);
      setValue("chartFavoriteId", item.id);
      onCloseModal();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Flex height="52px" p="15px" alignItems="center" columnGap="16px">
      <IconButton
        variant="icon"
        aria-label="Favorite"
        onClick={handleToggleFavorite}
        isLoading={isUpdateChartFavoriteLoading}
        isDisabled={isUpdateChartFavoriteLoading}
      >
        <Icon
          as={item.isFavorite ? MdFavorite : MdFavoriteBorder}
          color={item.isFavorite ? "blue" : "gray"}
        />
      </IconButton>
      <Text flex={1} sx={{ cursor: "pointer" }} onClick={handleSelectFavorite}>
        {item.title}
      </Text>
      <Box display="flex" gap={2} alignItems="center">
        {isEdit && (
          <>
            <IconButton variant="icon" aria-label="Delete" onClick={onOpen}>
              <Icon as={Edit} color="blue" />
            </IconButton>
            <IconButton
              variant="icon"
              aria-label="Delete"
              onClick={onDelete}
              isLoading={isDeleteLoading}
            >
              <Icon as={MinusRoundFilled} color="red" />
            </IconButton>
          </>
        )}
      </Box>
      {isOpen && (
        <ChartFavoriteRenameModal
          item={item}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  );
}

type ChartFavoritesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  chartFavorites: ChartFavorite[];
  sectionCode: string;
  chartFavoriteIdSelected?: string | null;
  chartCode: string;
};

function ChartFavoritesModal({
  isOpen,
  onClose,
  chartFavorites,
  sectionCode,
  chartFavoriteIdSelected,
  chartCode,
}: ChartFavoritesModalProps) {
  const toast = useToast();
  const { getValues, setValue, reset } = useFormContext();

  const [search, setSearch] = useState("");
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);

  const { mutateAsync: addChartFavorite, isLoading: isAddFavoriteLoading } =
    useAddChartFavorite({ sectionCode });
  const {
    mutateAsync: deleteChartFavorite,
    isLoading: isDeleteFavoriteLoading,
  } = useDeleteChartFavorite({ id: deletingId ?? "", sectionCode });

  const handleAddNewElement = async () => {
    try {
      const form = getValues();
      const subsections = mapFormToPayload(form);
      const payload: ChartFavoriteRequest = {
        title: search,
        code: sectionCode,
        isFavorite: false,
        subSections: subsections
          .map((x) => ({
            code: x.code,
            answers: x.answers.map((answer) => ({
              qCode: answer.qCode.slice(5),
              value: answer.value,
            })),
          }))
          .filter((x) => x.code !== "chartFavoriteId"),
      };
      const response = await addChartFavorite(payload);

      setSearch("");
      reset(form);
      setValue("chartFavoriteId", response.data.id);
      toast({
        status: "success",
        description: formMessages.createSuccess("Chart Favorite"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  const handleDeleteElement = async () => {
    try {
      if (deletingId) {
        await deleteChartFavorite({});
        toast({
          status: "success",
          description: formMessages.deleteSuccess("Chart Favorite"),
        });
        setDeletingId(null);
      }
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  const filteredChartFavorites = useMemo(() => {
    if (chartFavorites.length > 0) {
      const filteredElements = chartFavorites.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          search === ""
      );
      return filteredElements;
    }
    return [];
  }, [search, chartFavorites]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end" }}
        height="99%"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          fontSize="1rem"
          padding="20px 16px 30px"
          alignItems="center"
        >
          <Box flex={1} />
          <Heading
            fontWeight="600"
            fontSize="1.0625rem"
            textAlign="center"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Icon as={MdFavoriteBorder} fontSize="24px" />
            Favorites
          </Heading>
          <Flex flex={1} justifyContent="flex-end">
            <Button variant="label" onClick={onClose} color="blue">
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody p="15px">
          <Label>Search/Add</Label>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            variant="search"
            bg="white"
          />
          <Flex justifyContent="space-around" mt="20px">
            <Button
              variant="outlineSquared"
              color="gray"
              borderColor="gray"
              disabled={search === ""}
              onClick={() => setSearch("")}
            >
              Clear Search
            </Button>
            <Button
              variant="outlineSquared"
              disabled={
                search === "" ||
                filteredChartFavorites.length > 0 ||
                isAddFavoriteLoading
              }
              onClick={handleAddNewElement}
              isLoading={isAddFavoriteLoading}
            >
              Add New
            </Button>
          </Flex>

          <Box
            width="100%"
            display="flex"
            justifyContent="end"
            pt="20px"
            pr={2}
          >
            <Button
              variant="label"
              onClick={() => setIsEdit((prev) => !prev)}
              color="blue"
            >
              {isEdit ? "Done" : "Edit List"}
            </Button>
          </Box>

          <Card overflowY="auto" overflowX="hidden" mt="20px">
            {filteredChartFavorites.map((item, i) => (
              <React.Fragment key={item.id}>
                <ChartFavoriteItem
                  item={item}
                  onDelete={() => setDeletingId(item.id)}
                  isDeleteLoading={
                    item.id === deletingId || isDeleteFavoriteLoading
                  }
                  isEdit={isEdit}
                  chartFavoriteIdSelected={chartFavoriteIdSelected}
                  onCloseModal={onClose}
                  chartCode={chartCode}
                />
                {i < filteredChartFavorites.length - 1 && (
                  <Divider marginLeft="52px" />
                )}
              </React.Fragment>
            ))}
          </Card>
        </ModalBody>
      </ModalContent>
      <WarningDialog
        isOpen={Boolean(deletingId)}
        onCancel={() => setDeletingId(null)}
        onAction={handleDeleteElement}
        onClose={() => setDeletingId(null)}
        title="Warning"
        mainText={`Are you sure you want to Delete this Favorite\rThis action cannot be undone!`}
      />
    </Modal>
  );
}

export { ChartFavoritesModal };
