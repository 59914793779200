import { AnyObject, ChartAnswer, Interaction } from "types";
import {
  ChartForm,
  ChartFormProcedurePayload,
  ChartFormProcedureSection,
  ChartFormSubsection,
  ChartFormSubsectionPayload,
  CustomRow,
} from "modules";

type SharedChartNoteApiUrlParams = {
  encounterId: string;
  sectionCode: string;
  subsectionCode?: string;
};

type ChartNoteApiUrlParams = {
  encounterId: string;
  chartCode: string;
  section: string;
  subsection?: string;
};

function getSharedChartNoteApiURL(params: SharedChartNoteApiUrlParams) {
  const { encounterId, sectionCode, subsectionCode } = params;

  if (subsectionCode) {
    return `v1/ChartNote/${encounterId}/chart/${sectionCode}/${subsectionCode}`;
  }

  return `v1/ChartNote/${encounterId}/chart/${sectionCode}`;
}

function getSharedChartAudioNoteApiURL(params: SharedChartNoteApiUrlParams) {
  const { encounterId, sectionCode, subsectionCode } = params;

  if (subsectionCode) {
    return `v1/ChartNote/audio/${encounterId}/chart/${sectionCode}/${subsectionCode}`;
  }

  return `v1/ChartNote/audio/${encounterId}/chart/${sectionCode}`;
}

function getChartNoteApiURL(params: ChartNoteApiUrlParams) {
  const { encounterId, chartCode, section, subsection } = params;

  if (subsection) {
    return `v1/ChartNoteSection/${encounterId}/${chartCode}/${section}/${subsection}`;
  }

  return `v1/ChartNoteSection/${encounterId}/${chartCode}/${section}`;
}

function getChartAudioNoteApiURL(params: ChartNoteApiUrlParams) {
  const { encounterId, chartCode, section, subsection } = params;

  if (subsection) {
    return `v1/ChartNoteSection/audio/${encounterId}/${chartCode}/${section}/${subsection}`;
  }

  return `v1/ChartNoteSection/audio/${encounterId}/${chartCode}/${section}`;
}

function mapSubsectionToFormObject(
  answers: ChartAnswer[],
  auditCheck?: boolean,
  interactions?: Interaction[],
  customRows?: CustomRow[]
) {
  return {
    auditCheck,
    ...mapAnswersListToFormObject(answers, interactions),
    customRows,
  };
}

function mapAnswersListToFormObject(
  answers: ChartAnswer[],
  interactions?: Interaction[]
) {
  return answers.reduce<AnyObject>(
    (prev, { qCode, value }) => ({
      ...prev,
      [qCode]: value,
      ...(!prev.interactions && !!interactions && { interactions }),
    }),
    {}
  );
}

function mapPayloadToFormObject(subsections: ChartFormSubsection[]): ChartForm {
  return subsections.reduce<ChartForm>(
    (prev, { code, auditCheck, answers, interactions, customRows }) => ({
      ...prev,
      [code]: mapSubsectionToFormObject(
        answers,
        auditCheck,
        interactions,
        customRows
      ),
    }),
    {}
  );
}

function mapFormObjectToAnswersList(formObject: AnyObject): ChartAnswer[] {
  return Object.entries(formObject)
    .filter(
      ([qCode, value]) =>
        !!value &&
        qCode !== "interactions" &&
        qCode !== "auditCheck" &&
        qCode !== "customRows" &&
        qCode !== "chartFavoriteId"
    )
    .map(([key, value]) => ({
      qCode: key,
      value,
    }));
}

function mapFormToPayload(
  formObject: ChartForm,
  subsectionCheckedByDefault: string[] = []
) {
  return Object.entries(formObject)
    .filter(
      ([subsectionCode, _answers]) => subsectionCode !== "chartFavoriteId"
    )
    .map<ChartFormSubsectionPayload>(([subsectionCode, answersForm]) => {
      return {
        code: subsectionCode,
        answers: mapFormObjectToAnswersList(answersForm),
        auditCheck:
          (subsectionCheckedByDefault.includes(subsectionCode) ||
            (answersForm.auditCheck as boolean)) ??
          false,
        customRows: answersForm.customRows as CustomRow[],
      };
    });
}

function mapFormToPayloadProcedures(
  formObject: ChartForm,
  subsectionCheckedByDefault: string[] = []
) {
  const payload: ChartFormProcedurePayload = {
    sections: [],
    sedationNotes: [],
  };

  Object.entries(formObject).map(([combinedCode, answersForm]) => {
    const [sectionId, sectionCode, sectionSubCode, subsectionCode] =
      combinedCode.split("_");

    if (sectionCode === "SPRO2") {
      const index = payload.sections.findIndex((el) => el.id === sectionId);

      if (index !== -1) {
        payload.sections[index].subSections.push({
          code: subsectionCode,
          answers: mapFormObjectToAnswersList(answersForm),
          auditCheck:
            (subsectionCheckedByDefault.includes(subsectionCode) ||
              (answersForm.auditCheck as boolean)) ??
            false,
          customRows: answersForm.customRows as CustomRow[],
        });
      } else {
        payload.sections.push({
          id: sectionId,
          code: sectionSubCode,
          subSections: [
            {
              code: subsectionCode,
              answers: mapFormObjectToAnswersList(answersForm),
              auditCheck:
                (subsectionCheckedByDefault.includes(subsectionCode) ||
                  (answersForm.auditCheck as boolean)) ??
                false,
              customRows: answersForm.customRows as CustomRow[],
            },
          ],
        });
      }
    } else if (sectionCode === "SPSN2") {
      const index = payload.sedationNotes.findIndex(
        (el) => el.id === sectionId
      );
      if (index !== -1) {
        payload.sedationNotes[index].subSections.push({
          code: subsectionCode,
          answers: mapFormObjectToAnswersList(answersForm),
          auditCheck:
            (subsectionCheckedByDefault.includes(subsectionCode) ||
              (answersForm.auditCheck as boolean)) ??
            false,
          customRows: answersForm.customRows as CustomRow[],
        });
      } else {
        payload.sedationNotes.push({
          id: sectionId,
          code: sectionSubCode,
          subSections: [
            {
              code: subsectionCode,
              answers: mapFormObjectToAnswersList(answersForm),
              auditCheck:
                (subsectionCheckedByDefault.includes(subsectionCode) ||
                  (answersForm.auditCheck as boolean)) ??
                false,
              customRows: answersForm.customRows as CustomRow[],
            },
          ],
        });
      }
    }
  });

  return payload;
}

function mapPayloadToFormObjectProcedure(
  info: ChartFormProcedurePayload
): ChartForm {
  const parseSections = (
    sections: ChartFormProcedureSection[],
    sectionCode: string
  ) => {
    return sections.reduce<ChartForm>((acc, section) => {
      section.subSections.forEach((subSection) => {
        const key = `${section.id}_${sectionCode}_${section.code}_${subSection.code}`;
        acc[key] = mapSubsectionToFormObject(subSection.answers);
      });
      return acc;
    }, {});
  };

  return {
    ...parseSections(info.sections ?? [], "SPRO2"),
    ...parseSections(info.sedationNotes ?? [], "SPSN2"),
  };
}

type Result = {
  code: string;
  action: "updated" | "removed" | "added";
  previousValue: unknown;
  newValue: unknown;
  qCode?: string;
};

function findChartFormDifferences(
  oldArray: ChartFormSubsectionPayload[],
  newArray: ChartFormSubsectionPayload[]
): Result[] {
  const results: Result[] = [];

  // Find added, removed, and modified items
  newArray.forEach((newItem) => {
    const oldItem = oldArray.find((item) => item.code === newItem.code);

    if (!oldItem) {
      // Added item
      newItem.answers.forEach((answer) => {
        results.push({
          code: newItem.code,
          action: "added",
          previousValue: "",
          newValue: answer.value,
          qCode: answer.qCode,
        });
      });
    } else {
      // Check for modified or removed answers
      newItem.answers.forEach((newAnswer) => {
        const oldAnswer = oldItem.answers.find(
          (answer) => answer.qCode === newAnswer.qCode
        );

        if (!oldAnswer) {
          // Answer added
          results.push({
            code: newItem.code,
            action: "added",
            previousValue: "",
            newValue: newAnswer.value,
            qCode: newAnswer.qCode,
          });
        } else if (oldAnswer.value !== newAnswer.value) {
          // Answer modified
          results.push({
            code: newItem.code,
            action: "updated",
            previousValue: oldAnswer.value,
            newValue: newAnswer.value,
            qCode: oldAnswer.qCode,
          });
        }
      });

      oldItem.answers.forEach((oldAnswer) => {
        const newAnswer = newItem.answers.find(
          (answer) => answer.qCode === oldAnswer.qCode
        );

        if (!newAnswer) {
          // Answer removed
          results.push({
            code: oldItem.code,
            action: "removed",
            previousValue: oldAnswer.value,
            newValue: "",
            qCode: oldAnswer.qCode,
          });
        }
      });
    }
  });

  return results;
}

export type { ChartNoteApiUrlParams, SharedChartNoteApiUrlParams };
export {
  mapAnswersListToFormObject,
  mapPayloadToFormObject,
  mapFormObjectToAnswersList,
  mapFormToPayload,
  mapFormToPayloadProcedures,
  mapPayloadToFormObjectProcedure,
  getChartAudioNoteApiURL,
  getChartNoteApiURL,
  getSharedChartAudioNoteApiURL,
  getSharedChartNoteApiURL,
  findChartFormDifferences,
};
