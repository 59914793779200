import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFavorite } from "modules/charts-shared/types";

type ChartFavoriteProps = {
  sectionCode: string;
};

function useChartFavorites({ sectionCode }: ChartFavoriteProps) {
  return useAxiosQuery(["chartFavorites", { sectionCode }], () =>
    axiosChartInstance.get<ChartFavorite[]>(`v1/favorites/${sectionCode}`)
  );
}

export { useChartFavorites };
