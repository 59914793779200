import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseUpdateChartApplicationParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
};

function useUpdateChartApplication(params: UseUpdateChartApplicationParams) {
  const { encounterId, chartCode, sectionId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["chartApplication", { chartCode, sectionId }],
    ({
      input,
      chartFavoriteId,
    }: {
      input: ChartFormSubsectionPayload[];
      chartFavoriteId?: string;
    }) =>
      axiosChartInstance.put(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionId}`,
        input,
        { params: { chartFavoriteId } }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartApplication",
          { encounterId, chartCode, sectionId },
        ]);
        queryClient.invalidateQueries([
          "chartDisposition",
          { encounterId, chartCode, sectionId },
        ]);
        queryClient.invalidateQueries([
          "chartSectionInfo",
          { encounterId, chartCode, sectionId },
        ]);
      },
    }
  );
}

export type { UseUpdateChartApplicationParams };
export { useUpdateChartApplication };
